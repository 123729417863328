import {useState} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function CreateUser() {
    const [inputs, setInputs] = useState([]);
    const navigate = useNavigate();
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
        // setInputs makes input to be like: {name: 'NameValue', email: 'EmailValue', mobile: 'MobileValu'}
        // console.log("handleChange(event): inputs == ");
        // console.log(inputs);
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(inputs);
        axios.post('https://api.softdefault.com/api/user/save', inputs).then(function(response){
        console.log("handleSubmit: response from server:");
        console.log(response.data);
        navigate('/'); // should probably only do this if we know we're successful.
    });
    }
    return (
        <div>
            <h1>Create user</h1>
            <form onSubmit={handleSubmit}>
                <table cellSpacing="10">
                    <tbody>
                        <tr>
                            <th>
                                <label>Name: </label>
                            </th>
                            <td>
                                <input type="text" name="name" onChange={handleChange} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>Email: </label>
                            </th>
                            <td> 
                                <input type="text" name="email" onChange={handleChange} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>Mobile: </label>
                            </th>
                            <td>
                                <input type="text" name="mobile" onChange={handleChange} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align ="right">
                                <button>Save</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
  }