import axios from "axios"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ListUser() {
    
    const [users, setUsers] = useState([]);

    const deleteUser = (id) => {
        axios.delete(`https://api.softdefault.com/api/user/${id}/delete`).then(function(response){
        console.log(`Response from /api/user/${id}/delete:`);
        console.log(response.data);
        getUsers();
    });
}
    
    useEffect(() => {
        getUsers();
    }, []); // executes just once.
    
    function getUsers() {
        
        // attempt to get the users from the backend
        axios.get('https://api.softdefault.com/api/users/').then(function(response) {
            console.log("Response data from /api/users/:");
            console.log(response.data);
            setUsers(response.data); // expecting an array of user data?
        });
        /* setUsers([
            {
                id:1,
                name: "Name1", 
                email:"email1", 
                mobile:"1234567890"},
            {
                id:1,
                name: "Name2", 
                email:"email2", 
                mobile:"0987654321",
            },
        ]);
        */
    }
    return (
        <div>
            <h1>List Users</h1>
            <p>Number of users found: {users.length}</p>
            <table className="styled-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) =>
                        <tr key={index}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.mobile}</td>
                            <td>
                                <Link to={`user/${user.id}/edit`} style={{marginRight: "10px"}}>Edit</Link>
                                <button onClick={() => deleteUser(user.id)}>Delete</button>
                            </td>
                        </tr>
                    )}

                </tbody>
            </table>
        </div>
    )
}